import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import Button from "./button"
import { primaryLight } from "./themes"
import { FaArrowRight } from "react-icons/fa"
import { Colors, Spacing, Typography } from "../styles/variables"

const FeaturedWorkCard = ({
  imageSrc,
  imageAlt,
  previewLabels,
  previewHeading,
  previewText,
  projectLink,
}) => (
  <Fade delay={1000}>
    <OuterContainer to={projectLink}>
      <ImageContainer>
        <PreviewImage src={imageSrc} alt={imageAlt} />
      </ImageContainer>
      <TextContainer>
        <PreviewLabels>{previewLabels}</PreviewLabels>
        <PreviewHeading>{previewHeading}</PreviewHeading>
        <PreviewText>{previewText}</PreviewText>
        <ButtonLink to={projectLink}>
          <Button
            theme={primaryLight}
            label={"View Case Study"}
            icon={<FaArrowRight />}
          />
        </ButtonLink>
      </TextContainer>
    </OuterContainer>
  </Fade>
)

const OuterContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${Spacing.cardHeight};
  margin-bottom: ${Spacing.huge};
  border: 1px solid ${Colors.gray1};
  border-radius: 12px;
  flex: 1;
  min-height: 720px;
  background: ${Colors.white};
  max-width: 640px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: none;

  &:hover {
    background: ${Colors.offWhite};
  box-shadow: 1px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${Spacing.breakPoint}) {
    flex-direction: column;
    margin-bottom: ${Spacing.medium};
    max-width: 100%;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: ${Spacing.breakPoint}) {
    width: 100%;
    // height: 11em;
  }
`

const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: ${Spacing.base} ${Spacing.medium};

  @media (max-width: ${Spacing.breakPoint}) {
    width: 100%;
    padding: ${Spacing.base} ${Spacing.small};
  }
`

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  // border: 1px solid ${Colors.gray1};
`

const PreviewLabels = styled.p`
  font-size: ${Typography.xSmallFontSize};
  font-weight: ${Typography.lightFontWeight};
  color: ${Colors.gray3};
  margin-bottom: ${Spacing.base};
  text-transform: uppercase;
  letter-spacing: 2px;
`

const PreviewHeading = styled.h3`
  margin-bottom: ${Spacing.xSmall};
`

const PreviewText = styled.p`
  margin-bottom: ${Spacing.base};
  color: ${Colors.gray4};

  @media (max-width: ${Spacing.breakPoint}) {
    font-size: ${Typography.smallFontSize};
  }
`

const ButtonLink = styled(Link)`
  display: flex;
  text-decoration: none;
`

export default FeaturedWorkCard
