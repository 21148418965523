import React from "react"
import styled, { css } from "styled-components"
import Fade from "react-reveal/Fade"
import { Link as ScrollLink } from "react-scroll"
import Button from "./button"
import { secondaryLight } from "./themes"
import { FaArrowDown } from "react-icons/fa"
import { Images } from "../images"
import { Colors, Spacing, Typography } from "../styles/variables"

const Intro = () => (
  <Container id="top">
    <Nav>
      <Logo src={Images.IshanLogo} alt="Design | @ishandeveloper" />
      <NavItems>
        <NavItem href="https://blog.ishandeveloper.com/authors/Ishan-Sharma">
          About
        </NavItem>
        <NavItem href="https://blog.ishandeveloper.com/">Blog</NavItem>
      </NavItems>
    </Nav>
    <HeadingContainer>
      <Fade delay={500}>
        <Heading>
          Hi there, I’m <BlueText>Ishan</BlueText>! A developer/designer
          passionate about crafting purpose-driven experiences that bring people together.
        </Heading>
      </Fade>
    </HeadingContainer>

    <Fade delay={1500}>
      <ButtonContainer>
        <ScrollLink
          activeClass="active"
          to={"skills"}
          spy={true}
          smooth={true}
          duration={1000}
        >
          <Button
            theme={secondaryLight}
            label={"Wecome!"}
            icon={<FaArrowDown />}
          />
        </ScrollLink>
      </ButtonContainer>
    </Fade>

    {/* <HeadingContainer>
      <Fade delay={500}>
        <Heading>Hey, I'm <BlueText>Ishan</BlueText></Heading>
      </Fade>
      <Fade delay={750}>
        <WaveAnimation>
          <WaveIcon src={Images.Wave} alt="" />
        </WaveAnimation>
      </Fade>
    </HeadingContainer>
    <Fade delay={1500}>
      <Subheading>
        I craft experiences on web & mobile.
      </Subheading>
    </Fade>
    <Fade delay={2500}>
      <ButtonContainer>
        <ScrollLink
          activeClass="active"
          to={"skills"}
          spy={true}
          smooth={true}
          duration={1000}
        >
          <Button
            theme={secondaryLight}
            label={"Wecome!"}
            icon={<FaArrowDown />}
          />
        </ScrollLink>
      </ButtonContainer>
    </Fade> */}
  </Container>
)

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0rem;
`

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavItem = styled.a`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
  transition: all ease-in-out 250ms;

  &:hover {
    color: ${Colors.bluish};
  }
`

const Logo = styled.img`
  height: 92px;
`

const Container = styled.section`
  display: flex;
  flex-direction: column;
  // min-height: 50vh;
  padding: 0 ${Spacing.sectionPadding};

  @media (max-width: ${Spacing.breakPoint}) {
    padding: 0 ${Spacing.sectionPaddingMobile};
  }
`

const HeadingContainer = styled.div`
  display: flex;
  padding: 2rem 1rem;
`

const Heading = styled.h1`
  font-size: 2.75rem;
  margin-bottom: 0.35em;
  font-weight: 300;

  @media (max-width: ${Spacing.breakPoint}) {
    font-size: ${Typography.smallHeadingFontSize};
  }
`


const Subheading = styled.h2`
  ${Typography.subheading};
  color: ${Colors.gray4};
  margin-bottom: ${Spacing.base};

  @media (max-width: ${Spacing.breakPoint}) {
    max-width: 14em;
  }
`

const BlueText = styled.span`
  color: ${Colors.bluish};
  font-weight: 500;
`

const ButtonContainer = styled.div`
  display: flex;
  padding: 0rem 1rem;
`

export default Intro
